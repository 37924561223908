.main-sidebar{
    padding:1.9rem;
    padding-top: 0.3rem;

    transform: translateX(0%);
    transition: transform 0.5s ease;
    left: 0;
    right: auto;
    top: 0;
    height: 100%;
    overflow: auto;
    position: fixed;
    will-change: transform;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
}

.main-sidebar > .title-header{
    text-align: center;
    margin-bottom: 3rem;
}

.sidebar-menu{
    font-family: var(--ip-font);
    font-weight: 400;
    list-style: none;
    padding-left: 0;
}

.sidebar-menu li{
    margin-bottom: 1.5rem;
}

.sidebar-menu li a{
    text-decoration: none;
    color: var(--dark-blue);;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    cursor: pointer;
}

.sidebar-menu li a:hover, .sidebar-menu li a.active{
    color: var(--ip-blue);
  }

.sidebar-menu li a span{
    vertical-align: middle;
    padding-right: 0.6rem;
    font-size: 22px;
    color: var(--light-gray);;
}
.sidebar-menu li a:hover span, .sidebar-menu li a.active span{
    color: var(--ip-blue);
  }

.sidebar-profile{
    margin-bottom: 3rem;
    width: 220px;

}
.avatar-img {

    width: 46px;
    height: 46px;
    border-radius: 25px;
    border: 1px solid #C2CFE0;
    float: left;
    object-fit: cover;
}
.avatar-img-100 {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    aspect-ratio: 1 !important;
  }

.avatar-name {
    font-weight: 500;
    color: #000;
    word-break: break-word;
}

.avatar-email {
    font-weight: 400;
    color: #90A0B7;
    word-break: break-all;
}

.avatar-details{
    padding: 0.3rem;
    margin-left: 3.3rem;
  }