.loading-img { 
  width: 8rem;
  height: 9rem;
  transition: all 3s ease-in-out; 
  }
.loading-title
  {
    font-size: 2.5rem;
    font-weight: 400;
  }
.loading-subtitle
  {
    font-weight: 200;
font-size: 1.5rem;
  } 

  .elementToFadeInAndOut {
   
    animation: loadingAnimation 5s linear 1s infinite normal both; 
}

@keyframes loadingAnimation {
	0%,
	100% {
		transform: translateX(0%);
		transform-origin: 50% 50%; 
	} 

	15% {
		transform: translateX(-30px) rotate(-6deg);
	}

	30% {
		transform: translateX(15px) rotate(6deg);
	}

	45% {
		transform: translateX(-15px) rotate(-3.6deg);
	}

	60% {
		transform: translateX(9px) rotate(2.4deg);
	}

	75% {
		transform: translateX(-6px) rotate(-1.2deg);
	}
}