
  .header-bar{
    height: 50px;
    background: #f8f9fa;
    border-bottom: 1px solid #EBEFF2;
  }

  .header-profile-img{
    width: 30px;
    height: 30px;
    object-fit: cover;  
    border-radius: 50%;
  }
  .header-profile-name{
    font-weight: 500;
    color: var(--dark-gray);
    font-size: 13px;
    margin-left: 0.6rem;
  }