
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/bootstrap5.css";
@import './theme.css';
@import "primereact/resources/primereact.min.css";
@import 'primeicons/primeicons.css';


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --ip-blue: #109CF1;
  --white: #ffffff;
  --gray: #6A707E;
  --light-gray: #90A0B7;
  --table-gray: #707683;
  --dark-gray: #333;
  --dark-blue: #334D6E;
  --header-gray: #323C47;
  --status-cancelled: #FF3C5F;
  --status-pending-approval: #1565D8;
  --status-searching-coach: #FF7A00;
  --status-select-coach: #F5C02F;
  --status-planned: #1DD1A1;
  --status-executed: #9B51E0;
  --status-archived: #AEAEAE;
  --status-draft: #AEAEAE;
  --bs-offcanvas-width: 350px;
  --ip-font: 'Poppins', "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html{
  font-size: 16px;
}

body {
  font-family: var(--ip-font);
  background-color: #f4f6f8;
  font-size: 14px;
}

@media (min-width: 992px) {
  #wrapper {
    display: grid;
    grid-template-areas:"sidebar main";
    grid-template-columns: 1fr 11fr;
    gap: 0rem;
  }

  
}

aside {
  background: #fff;
  box-shadow: 0 4px 10px 5px #e1e1e1;
  grid-area: sidebar;
  min-height: 100vh;
  width: 260px;
  z-index: 0;
}
.content{
  grid-area: main;
}

.offcanvas.offcanvas-start {
  width: 300px;
}

a {
  text-decoration: none;
}

.e-schedule .e-month-view .e-appointment .e-appointment-details {
  padding-top: 3px;
}


.e-appbar.e-light {
  background: #f8f9fa;
  color: #212529;
  border-color: #f8f9fa;
  border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
  border-bottom: 1px solid #EBEFF2;
}

.e-control {
  font-family: var(--ip-font)
}

.e-badge.e-badge-danger:not(.e-badge-ghost):not([href]), .e-badge.e-badge-danger[href]:not(.e-badge-ghost) {
  background-color: #F7685B;
  color: #fff;
  border-radius: 12px;
  min-width: 17px;
  height: 17px;
  font-size: 10px;
  line-height: 10px;
}

.main{
  padding: 0rem;
}

.gray{
  color: var(--gray);
}

.ip-blue{
  color: var(--ip-blue);
}

.form-control, .form-select{
  border: 1px solid var(--dark-gray);
  
}

.form-control:focus, .form-select:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  border-color: var(--dark-gray);
  outline: 0;
  box-shadow: 0px 0 7px 0.25rem rgba(147, 148, 149, 0.25);
}

.form-control-light, .form-select-light{
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #e4e4e4;
}
.form-control-light:focus, .form-select-light:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  border-color: #e4e4e4;
  outline: 0;
  box-shadow: 0px 0 7px 0.25rem rgba(147, 148, 149, 0.25);
}

.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  background-color: var(--bs-form-control-bg);
  border-color: #e4e4e4;
  outline: 0;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 0 7px 0.25rem rgba(147, 148, 149, 0.25);
  border-radius: 6px;
}
.e-input-group:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: #e4e4e4;
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
}


.e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: #e4e4e4;
  box-shadow: 0px 0 7px 0.25rem rgba(147, 148, 149, 0.25);
}

.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
  border-color: #e4e4e4;
  box-shadow: 0px 0 7px 0.25rem rgba(147, 148, 149, 0.25);
}

.btn{
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
}

.btn-primary{
  border: 1px solid var(--ip-blue);
  background-color: var(--ip-blue);
  color: var(--white);
}

.btn-primary:active, .btn-primary:hover, .btn-primary:focus-visible{
  border: 1px solid var(--ip-blue);
  background-color: var(--white);
  color: var(--ip-blue);
}

.btn-light{
  border: 1px solid var(--ip-blue);
  background-color: var(--white);
  color: var(--ip-blue);

}

.btn-light:active, .btn-light:hover, .btn-light:focus-visible{
  border: 1px solid var(--ip-blue);
  background-color: var(--ip-blue);
  color: var(--white);
}

.btn-default{
  border: 1px solid var(--dark-gray);
  background-color: var(--white);
  color: var(--dark-gray);

}

.btn-default:active, .btn-default:hover, .btn-default:focus-visible{
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  color: var(--white);
}

.card{
  border-radius: 10px;
  border: 0px transparent;
  box-shadow: 0px 1px 10px -1px rgba(0,0,0,0.14);
  -webkit-box-shadow: 0px 1px 10px -1px rgba(0,0,0,0.14);
  -moz-box-shadow: 0px 1px 10px -1px rgba(0,0,0,0.14);
}

#_dialog_wrapper_dialog-content {
  min-height: 300px!important;
}
#_dialog_wrapper {
  min-height: 500px!important;
}

#disruptive_slider .e-range, #disruptive_slider .e-slider-track{
  height: 25px;
}

#disruptive_slider .e-handle {
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  height: 30px;
  outline: none;
  position: absolute;
  touch-action: none;
  transition: left 300ms ease-out, right 300ms ease-out, bottom 300ms ease-out, transform 300ms ease-out;
  width: 20px;
  z-index: 10;
}

.cursor-pointer {
  cursor: pointer;
  color: #42A5F5;
}

.workshop-type-img {
  width: 3rem;
}

.card-block {
  border: 1px solid lightgrey;
  border-radius: 5px !important;
}

.card-body.show {
  display: block;
}

.bg-w{
  background-color: #fff;
}

.be-shadow{
 box-shadow: 10px 10px 12px 1px #eee;
}
.card {
  /*padding-bottom: 20px; */
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.1);
}
.card-userinfo{
  background-color: #FCFCFC;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  font-weight: 300;
}
.card-userinfo .label{
  font-weight: 400;
}
.action-card{
  font-size: 11px;
}
.radio {
  display: inline-block;
  border-radius: 0;
  box-sizing: border-box;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  height: 100%;
  padding: 20px  10px;
  box-shadow: 0px 0px 15px 3px #eee
}
.radio-item{
  
}
.radio-item input{
  display: none;
}

.radio-item label{
  height: 100%;
}

.radio:hover {
  box-shadow: 0px 0px 14px 5px #ddd;
  border: 3px solid var(--ip-blue);
}

.radio::active, 
.radio:focus, 
.radio:focus-within {
  background-color: #F5F9FF;
  border: 3px solid var(--ip-blue);
}

.radio.selected {
  box-shadow: 0px 0px 14px 5px #ddd;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
}

.radio small{
  font-size: 10.5px;
  color: #8692A6;
  font-weight: 400;
  display: block;
  line-height: 15px;
}
.selected {
  border: 3px solid var(--ip-blue);
  background-color: #F5F9FF;
}

.e-grid .e-headercell, .e-grid .e-detailheadercell {
  background-color: #ffffff;
}

.e-grid .e-headercelldiv {
  border: 0 none;
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 18px;
  line-height: 18px;
  margin: -7px -7px -7px -8px;
  overflow: hidden;
  padding: 0 0.4em;
  text-align: left;
  text-transform: none;
  -webkit-user-select: none;
  user-select: none;
  color: var(--gray)
}
.e-grid .e-headercell, .e-grid .e-detailheadercell {
  border-style: solid;
    border-top-style: solid;
  border-width: 0;
  font-size: 14px;
  font-weight: 700;
  height: 45px;
  overflow: hidden;
  padding: 8px 8px 9px;
  position: relative;
  text-align: left;
}

.e-grid .e-rowcell {
  border-style: solid;
    border-top-style: solid;
  border-width: 1px 0 0;
    border-top-width: 1px;
  display: table-cell;
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  padding: 10px 8px;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}

.table-gray{
  color: var(--table-gray);
}
.table-gray-badge{
  border: 1px solid var(--table-gray);
  border-radius: 5px;
  padding: 3px 11px;
  font-size: 12px;
  line-break: normal;

}

.badge-list{
  flex-wrap: wrap;
  gap: 0.5rem 0
}

.bg-cancelled{
  background-color: var(--status-cancelled);
}

.color-cancelled{
  color: var(--status-cancelled);
}

.border-cancelled{
  border-color:  var(--status-cancelled) !important;
}

.bg-pending-approval{
  background-color: var(--status-pending-approval);
}

.color-pending-approval{
  color: var(--status-pending-approval);
}

.border-pending-approval{
  border-color:  var(--status-pending-approval) !important;
}

.bg-searching-coach{
  background-color: var(--status-searching-coach);
}

.color-searching-coach{
  color: var(--status-searching-coach);
}

.border-searching-coach{
  border-color:  var(--status-searching-coach) !important;
}


.bg-select-coach{
  background-color: var(--status-select-coach);
}

.color-select-coach{
  color: var(--status-canceselect-coachlled);
}

.border-select-coach{
  border-color:  var(--status-select-coach) !important;
}

.bg-planned{
  background-color: var(--status-planned);
}

.color-planned{
  color: var(--status-planned);
}

.border-planned{
  border-color:  var(--status-planned) !important;
}

.bg-executed{
  background-color: var(--status-executed);
}

.color-executed{
  color: var(--status-executed);
}

.border-executed{
  border-color:  var(--status-executed) !important;
}

.bg-archived{
  background-color: var(--status-archived);
}

.color-archived{
  color: var(--status-archived);
}

.border-archived{
  border-color:  var(--status-archived) !important;
}

.bg-draft{
  background-color: var(--status-archived);
}

.color-draft{
  color: var(--status-archived);
}

.border-draft{
  border-color:  var(--status-archived) !important;
}

.workshop-list-header-status{
  border-bottom: 2px solid;
  color: var(--dark-gray);
  font-size: 14px;
}

.workshop-list-status-circle{
  width: 12px;
  display: inline-block;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.workshop-list-header-count{
  font-weight: 700;
  font-size: 14px;
  word-wrap: initial;
}

.e-schedule .e-month-view .e-appointment {
  background: #EDF8FF;
  border: 1px solid #EDF8FF;
  border-radius: 5px;
  color: var(--header-gray);
  cursor: default;
  height: 30px;
  overflow: hidden;
  position: absolute;
}

.e-headertext{
  font-weight: 600;
}
.e-grid {
  border-color: #fff;
}
.e-grid .e-gridheader {
  background-color: #fff;
  border-bottom-color: #dee2e6;
  border-top-color: #fff;
  color: #212529;
}


.thread-list-item{
  border: 0px transparent;
  display: block;
  padding: 10px 15px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1px 10px -1px rgba(0,0,0,0.14);
  -webkit-box-shadow: 0px 1px 10px -1px rgba(0,0,0,0.14);
  -moz-box-shadow: 0px 1px 10px -1px rgba(0,0,0,0.14);
}
.thread-list-item.active{
  background: var(--ip-blue)!important;
  text-decoration: none;
}
.thread-list-item > .thread-list-item-title{
  color: var(--dark-gray);
  text-decoration: none;
  font-size: 18px;
}
.thread-list-item > .thread-list-item-subtitle{
  color: var(--dark-gray);
  text-decoration: none;
  font-size: 14px;
}
.thread-list-item.active > .thread-list-item-title{
  color: var(--white);
}
.thread-list-item.active > .thread-list-item-subtitle{
  color: var(--white);
}
.thread-message-area{
  border: 0px transparent;
  display: block;
  padding: 10px 15px;
  border-radius: 10px;
  background: #fff;
  height: 70vh;
  box-shadow: 0px 1px 10px -1px rgba(0,0,0,0.14);
  -webkit-box-shadow: 0px 1px 10px -1px rgba(0,0,0,0.14);
  -moz-box-shadow: 0px 1px 10px -1px rgba(0,0,0,0.14);
}

.thread-message{
  border-radius: 15px;
  background-color: #F5F6F8;
  padding: 15px;
}
.thread-message span{
  font-size: 12px;
  font-weight: 600;
}
.thread-message-self{
  border-radius: 10px;
  background-color: var(--ip-blue);
  color: var(--white);
}
.thread-message-input, .thread-message-input:focus{
  border: 0;
  background-color: #F5F6F8;
}

.modal-header {
  border-bottom: 0px solid transparent;
}

.modal-footer {
  border-top: 0px solid transparent;
}

.modal-content {
  padding-left: 1rem;
  padding-right: 1rem;
}

.detail-header{
  font-size: 20px;
  font-weight: 600;
  color: var(--header-gray);
}

.label{
  font-size: 16px;
  font-weight: 500;
}

.text-light {
  --bs-text-opacity: 0;
  color: var(--dark-gray) !important;
}
.text-primary {
  --bs-text-opacity: 0;
  color: var(--ip-blue) !important;
}


.select-coach-img_placeholder{
  width: 150px;
  height: 150px;
  background-color: lightgray;
  border-radius: 100px;
  margin: auto;
    margin-bottom: auto;
  margin-bottom: 2rem;
}

.badge-secondary  {
  color: fff;
  background-color: #888;
  font-weight: 600;
  font-size: 12px;
}

.dashboard-bg-bottom-right{
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 16rem;
}

.dashboard-widget-header{
  
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--light-gray);
  margin-bottom: 1rem;

}

.wizard-step{
  font-size: 10px;
  width: 20px;
  height: 20px;
  background-color: #DADADA;
  color: #333333;
  border-radius: 3px;
  text-align: center;
  padding-top: 3px;
  font-weight: 600;
}

.wizard-step-active{

  background-color: var(--ip-blue);
  color: #fff;
}

.wizard-spacer{
  width: 30% !important;
  height: 2px;
  border: 1px solid #D0D0D0;
}

.wizard-progress-bar{
  background-color: var(--ip-blue);
}
.wizard-progress{
  height: 4px;
}

.e-switch-wrapper .e-switch-on, .e-css.e-switch-wrapper .e-switch-on {
  background-color: var(--ip-blue);
  color: #fff;
}
.e-switch-wrapper .e-switch-inner.e-switch-active, .e-css.e-switch-wrapper .e-switch-inner.e-switch-active {
  background-color: var(--ip-blue);
  border-color: var(--ip-blue);
}
.e-multi-select-wrapper .e-chips > .e-chipcontent {
  -webkit-text-fill-color: var(--table-gray);
  color: var(--table-gray);
  font-family: var(--ip-font);
  font-size: 12px;
}
.e-multi-select-wrapper .e-chips .e-chips-close::before {
  -webkit-text-fill-color: var(--table-gray);
  color: var(--table-gray);
}
.e-multi-select-wrapper .e-chips {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid var(--table-gray);
  padding: 3px 11px;
  height: auto;
}
.e-multiselect:not(.e-disabled) .e-multi-select-wrapper .e-chips:hover { 
  background-color: #fff;

  box-shadow: 0px 0 7px 0.25rem rgba(147, 148, 149, 0.25);

}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: var(--dark-gray);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--dark-gray);
  border-bottom: 2px solid;
  font-weight: 600;
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: 0 0;
  border: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: var(--light-gray);
  font-size: 16px;
}

.ql-toolbar.ql-snow {
  border: 1px solid #e4e4e4!important;
  box-sizing: border-box;
  font-family: var(--ip-font);
  padding: 8px;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-container {
  box-sizing: border-box;
  border: 1px solid #e4e4e4!important;
  font-family: var(--ip-font);
  font-size: 13px;
  margin: 0px;
  position: relative;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  min-height: 20vh;
}

.quill{
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.1);
}
.image-upload{
}
.image-upload-container{
  width: 120px;
  height: 120px;
  margin: auto;
  position: relative;
  cursor: pointer;
}
.image-upload-container img{
  width: 90px;
  height: 90px;
  margin: 15px;
  position: relative;
}
.image-upload-container:hover{
  border: 1px dashed var(--ip-blue);
  border-radius: 200rem;
}
.image-upload-container:hover img{
  margin: 14px;
}
.image-upload-container:hover .image-upload-placeholder{
  visibility: visible;
}
.image-upload-placeholder{
  color: var(--ip-blue);
  font-size: 50px;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 200rem;
  background-color: var(--light-gray);
  padding-top: 21px;
  background-color: rgba(255, 255, 255, 0.47);
  visibility: hidden;
}

.list-edit-icon  {
  width: 40px;
  height: 40px;
  border-radius: 190rem;
  font-size: 16px;
}

.method-title span.badge {
  font-weight: 500;
  font-size: 14px;
  padding: 4px 15px;
}
.e-container .e-palette .e-custom-tile {
  border: 0;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  margin: 4px;
  
}
.e-container {
  box-shadow: none;
}

.e-container .e-palette .e-tile.e-selected {
  outline: #fff 1.5px solid;
  position: relative;
  box-shadow: 0 0px 12px rgba(38, 35, 35, 0.99);
}

.w-10{
  width: 10%!important;
}
.e-popup-footer{
  display: none!important;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-subject-wrap .e-subject {
  background-color: transparent;
  border-left: 0px solid #0d6efd;
  border-radius: 4px;
  color: #212529;
  cursor: default;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  max-height: 87px;
  padding: 8px;
}

.color-picker-radio{
  border: 0;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  margin: 4px;
  cursor: pointer;
}

.color-picker-radio.color-selected, .color-picker-radio:hover {
  outline: #fff 1.5px solid;
  box-shadow: 0 0px 12px rgba(38, 35, 35, 0.99);
}

.border-bottom-gray{
  border-bottom: 1px solid var(--bluegray-100);
}

.calendar-animation-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.calendar-visible {
  max-height: 500px; /* Adjust based on the expected max height of your calendar */
}

/**
Primereact customization
**/
.p-column-title{
  color: var(--gray);
}

.p-paginator {
  justify-content: left;
}

.p-datatable .p-datatable-thead > tr > th {
  border-width: 0px 0 1px 0;
}
.p-datatable .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: #e9ecef;
}
.p-inputswitch .p-inputswitch-slider::before {
  background: #fff;
  width: 1.25rem;
  height: 1.25rem;
  left: .25rem;
  margin-top: -0.625rem;
  border-radius: 15px;
  transition-duration: .15s;
}
.p-inputswitch .p-inputswitch-slider {
  border-radius: 15px;
}
.p-component {
  font-family: var(--ip-font);
  font-size: inherit;
  font-weight: normal;
}

.p-slider-large.p-slider-horizontal {
  height: 2rem;
}

.p-slider-large .p-slider-range {
  border-radius: 5px;
}

.p-slider-large .p-slider-handle {
  height: 2.4rem;
  width: 1.5rem;
  border-radius: 13px;
  margin-top: -1.15rem!important;
  margin-left: -0.5715rem;  
  border: 1px solid #e9ecef;
}
.p-slider-large .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;  
  box-shadow: 0 0 0 -0.0rem rgba(38,143,255,.5);
}
.p-selectbutton .p-button
{
  background: #ffffff;
  border: 1px solid var(--light-gray);
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: var(--light-gray);
  color: var(--dark-gray);
  transition: background-color .15s,border-color .15s,box-shadow .15s;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover
{
  background: var(--ip-blue);
  border-color: var(--ip-blue);
  color: #fff;
}

.p-selectbutton .p-button.p-highlight
{
  background: var(--ip-blue);
  border-color: var(--ip-blue);
  color: #fff;
}
.p-selectbutton .p-button.p-highlight:hover
{
  background: #109CF1CC;
  border-color: #109CF1CC;
  color: #fff;
}
/**
END Primereact customization
**/